import React, { Component, ErrorInfo, ReactNode } from "react";

import Title from "#/elements/text/title/Title";

import './error-boundary.scss';


interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };
  props: any;

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-wrapper">
          <Title
            className="error-boundary-title"
            text={_t(
              'Sorry, there was an error.',
              'ECOMMPAY.ERROR_BOUNDARY.TITLE'
            )}
          />
        </div>
        )
    };

    return this.props.children;
  }
}

export default ErrorBoundary;
