import { IGetReceivedAmountProps, IPaymentPageParams, IPaymentPagePayload } from './types';

enum EpayEndpoints {
  GetReceivedAmount = 'v1/ecommpay/estimate',
  GetPaymentPageLink = 'v1/ecommpay/start',
}

export default class EpayService {
  private url: string;
  public currency: string;
  public session_id: string;

  constructor(url: string = '', session_id: string = '', currency: string = '') {
    this.url = url;
    this.session_id = session_id;
    this.currency = currency;
  }

  set session(id: string) {
    this.session_id = id;
  }

  set currentCurrency(newValue: string) {
    this.currency = newValue;
  }

  public async getReceivedAmount(params: IGetReceivedAmountProps): Promise<number> {
    const { amount, source_currency_id, target_currency_id } = params;
    const response = await fetch(`${this.url}/${EpayEndpoints.GetReceivedAmount}?amount=${amount}&source_currency_id=${source_currency_id}&target_currency_id=${target_currency_id}&payment_session_id=${this.session_id}`)

    return response.json();
  }

  public async getPaymentPageParams(params: IPaymentPagePayload): Promise<IPaymentPageParams> {
    const response = await fetch(`${this.url}/${EpayEndpoints.GetPaymentPageLink}?payment_session_id=${this.session_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    });
    
    return response.json();
  }
}
