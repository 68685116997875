import { epayScriptUrl } from '#/config/config-env';

export const addEpayScript = () => {
  if (!document.getElementById('epay-initialize')) {
    const scriptInit = document.createElement('script');

    scriptInit.id = 'epay-initialize';
    scriptInit.src = epayScriptUrl;

    document.getElementsByTagName('body')[0].appendChild(scriptInit);
  }
};