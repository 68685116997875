import translate from '#/util/translation/translate';

window._t = translate();

declare global {
  interface Window {
    attachEvent: any;
    EPayWidget: {
      run: Function;
      bind: Function;
    };
  }
}

export default {};
