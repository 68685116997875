export * from '#/types/elements';
// export * from '#/components/KYCForm/enums';

export enum Themes {
  Light = 'light',
  Dark = 'dark',
  LocalStorageThemeItem = 'theme',
}

export enum Status {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failed = 'FAILED',
  None = 'NONE',
  Verify = 'VERIFY',
  Verified = 'VERIFIED',
  Agreement = 'AGREEMENT',
  New = 'NEW',
}

export enum RequestStatus {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failed = 'FAILED',
  None = 'NONE',
}

export enum ToastThemes {
  Light = 'light',
  Dark = 'dark',
  Colored = 'colored',
}

export enum UrlSearchParamsEnum {
  SessionId = 'session-id',
  Theme = 'theme',
  SelectedCurrency = 'selected-currency',
  SourceCurrency = 'source-currency',
  Language = 'language',
  AccessToken = 'access-token',
  ClientName = 'client-name',
  CustomerFirstName = 'customer-first-name',
  CustomerLastName = 'customer-last-name',
  MinAmount = 'min-amount',
  MaxAmount = 'max-amount',
  AvailableAmount = 'available-amount',
  InitialAmount = 'initial-amount',
}

export enum EcommpayEnv {
  Staging = 'Staging',
  Production = 'Production',
}

export enum EBuyCryptoCurrencies {
  USD = 'USD',
  EURC = 'EURC',
}
