import { useEffect } from 'react';
import { useAppSelector } from '../redux';
import { PaymentSteps, selectPaymentStep } from '#/store/reducers/ecommpay/ecommpay';

enum ECPMessage {
  Resize = 'epframe.resize',
  Loaded = 'epframe.loaded',
}

const usePostMessage = () => {
  const currentStep: PaymentSteps = useAppSelector(selectPaymentStep);

  useEffect(() => {
    const sendHeight = (height: number) => {
      window.parent.postMessage({ type: 'setHeight', height }, '*');
    };

    if (currentStep === PaymentSteps.PreparePaymentPage) {
      sendHeight(400);
    }

    const handleEcpResize = ({ data }: MessageEvent) => {
      try {
        if (typeof data === 'string') {
          const parsedData = JSON.parse(data);

          if (parsedData.message === ECPMessage.Resize && parsedData.data?.height) {
            sendHeight(parsedData.data?.height);
          }
        }
      } catch (e) {
        console.log('parse error', e);
      }
    };

    window.addEventListener('message', handleEcpResize, false);

    return () => {
      window.removeEventListener('message', handleEcpResize);
    };
  }, [currentStep]);
};

export default usePostMessage;
