import { ReactElement } from 'react';
import { NovaElementsOptions, NovaElementsSizes } from '#/types/elements';

import './title.scss';

export interface Props {
  text: string;
  children?: ReactElement;
  onClick?: Function;
  variant?: NovaElementsOptions;
  className?: string;
  size?: NovaElementsSizes;
  isDark?: boolean;
}
const Title = (props: Props) => {
  const {
    text = '',
    children = null,
    onClick = null,
    className = '',
    variant = NovaElementsOptions.Primary,
    size = NovaElementsSizes.Medium,
    isDark = false,
  } = props;

  return (
    <p
      className={`nova-title nova-title-${variant} ${className} ${size} ${isDark ? 'dark' : ''} ${
        onClick ? 'clickable' : ''
      }`}
      onClick={e => onClick && onClick(e)}
    >
      {text}
      {children}
    </p>
  );
};

export default Title;
