import { ReactElement, LazyExoticComponent, Suspense, FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { selectRoutesConfig } from '#/store/reducers/config/config';
import { IRoutes } from '#/route/types';
import Loader from '#/elements/other/loader/Loader';

interface Props {
  components: {
    [name: string]: LazyExoticComponent<any> | ReactElement | Element;
  };
}
const Router = ({ components }: Props) => {
  const routes = useSelector(selectRoutesConfig);

  const renderRoute = (route: IRoutes) => {
    const Component = components[route.component] as FC<any>;;

    return (
      <Route
        key={`route-${route.path}`}
        path={route.path}
        index={route.path === '/'}
        element={
          <Suspense fallback={<Loader isAltLoader />}>
            <Component {...route} />
          </Suspense>
        }
      />
    );
  };

  const renderListRoutes = () => routes.map((route: IRoutes) => renderRoute(route)).filter((route: any) => !!route);

  return (
    <>
      <Routes>{renderListRoutes()}</Routes>
    </>
  );
};

export default Router;
