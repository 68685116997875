//Core
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
//Components
import App from './App';
import ErrorBoundary from '#/components/ErrorBoundary/ErrorBoundary';
//Store
import { setupStore } from './store/store';
//Others
import { addEpayScript } from './sdk';
import './globals';
import './index.css';

addEpayScript();

const store = setupStore();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as HTMLElement);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>
);
