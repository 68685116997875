import React, { useEffect, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import usePostMessage from '#/hooks/epay/usePostMessage';
import { useAppDispatch } from '#/hooks';

import { SessionStatus, selectSessionStatus } from '#/store/reducers/session/session';
import { initSession } from '#/store/reducers/session/thunk';

import Loader from '#/elements/other/loader/Loader';
import Title from '#/elements/text/title/Title';
import { UrlSearchParamsEnum } from '#/types';

interface Props {
  children: ReactElement<any, any>;
}

const SessionProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const [params] = useSearchParams();
  const paramsEntries = params.entries();

  const dispatch = useAppDispatch();
  const status: SessionStatus = useSelector(selectSessionStatus);
  const clientName: string | null = params.get(UrlSearchParamsEnum.ClientName);

  usePostMessage();

  useEffect(() => {
    dispatch(initSession(Object.fromEntries(paramsEntries) as { [key in UrlSearchParamsEnum]: string }));
  }, [dispatch, JSON.stringify(paramsEntries)]);

  switch (status) {
    case SessionStatus.Live:
      return <div className={`session-wrapper ${clientName || ''}`}>{children}</div>;
    case SessionStatus.Invalid:
      return (
        <Title
          className="session-error-text"
          text={_t(
            'Session id is not provided',
            'ECOMMPAY.SESSION_ID_NOT_PROVIDED',
          )}
        />
      );
    default:
      return <Loader isAltLoader />;
  }
}

export default SessionProvider;
