import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '#/store/store';
import { RequestStatus } from '#/types';
import { IPaymentPageParams } from '#/api/epay/types';

export enum PaymentSteps {
  PreparePaymentPage,
  PaymentPage,
}

export interface IEcommpayState {
  paymentPageParams: IPaymentPageParams | null,
  getPaymentPageParamsStatus: RequestStatus,
  receivedAmount: number,
  getReceivedAmountStatus: RequestStatus,
  paymentStep: PaymentSteps;
}

export const initialStateEcommpay: IEcommpayState = {
  paymentPageParams: null,
  getPaymentPageParamsStatus: RequestStatus.None,
  receivedAmount: 0,
  getReceivedAmountStatus: RequestStatus.None,
  paymentStep: PaymentSteps.PreparePaymentPage,
};

const ecommpaySlice = createSlice({
  name: 'ecommpay',
  initialState: initialStateEcommpay,
  reducers: {
    clearPaymentPageParams(state) {
      state.paymentPageParams = null;
      state.getPaymentPageParamsStatus = RequestStatus.None;
    },
    setPaymentPageParams(state, action: PayloadAction<IPaymentPageParams>) {
      state.paymentPageParams = action.payload;
      state.getPaymentPageParamsStatus = RequestStatus.Success;
    },
    setGetPaymentPageParamsStatus(state, action: PayloadAction<RequestStatus>) {
      state.getPaymentPageParamsStatus = action.payload;
    },
    setReceivedAmount(state, action: PayloadAction<number>) {
      state.receivedAmount = action.payload;
      state.getReceivedAmountStatus = RequestStatus.Success;
    },
    setGetReceivedAmountStatus(state, action: PayloadAction<RequestStatus>) {
      state.getReceivedAmountStatus = action.payload;
    },
    setPaymentStep(state, action: PayloadAction<PaymentSteps>) {
      state.paymentStep = action.payload;
    },
  },
});

export const {
  setGetPaymentPageParamsStatus,
  setGetReceivedAmountStatus,
  setPaymentPageParams,
  setReceivedAmount,
  clearPaymentPageParams,
  setPaymentStep,
} = ecommpaySlice.actions;

export default ecommpaySlice.reducer;

const selectEcommpayState = (state: RootState): IEcommpayState => state.ecommpay;

export const selectPaymentPageParams: (state: RootState) => IPaymentPageParams | null = createDraftSafeSelector(
  selectEcommpayState,
  (ecommpayState: IEcommpayState) => ecommpayState.paymentPageParams,
);

export const selectGetPaymentPageParamsStatus: (state: RootState) => RequestStatus = createDraftSafeSelector(
  selectEcommpayState,
  (ecommpayState: IEcommpayState) => ecommpayState.getPaymentPageParamsStatus,
);

export const selectReceivedAmount: (state: RootState) => number = createDraftSafeSelector(
  selectEcommpayState,
  (ecommpayState: IEcommpayState) => ecommpayState.receivedAmount,
);

export const selectGetReceivedAmountStatus: (state: RootState) => RequestStatus = createDraftSafeSelector(
  selectEcommpayState,
  (ecommpayState: IEcommpayState) => ecommpayState.getReceivedAmountStatus,
);

export const selectPaymentStep: (state: RootState) => PaymentSteps = createDraftSafeSelector(
  selectEcommpayState,
  (ecommpayState: IEcommpayState) => ecommpayState.paymentStep,
);
