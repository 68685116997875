import { useDispatch, useSelector } from 'react-redux';
import { selectSiteTheme, updateSiteTheme } from '#/store/reducers/config/config';
import { Themes } from '#/types/enums';

const useTheme = () => {
  const dispatch = useDispatch();
  const theme = useSelector(selectSiteTheme);
  const isLightTheme = theme === Themes.Light;

  const toggleTheme = (_theme?: Themes) => {
    dispatch(updateSiteTheme(_theme || theme === Themes.Dark ? Themes.Light : Themes.Dark));
  };

  return {
    theme,
    isLightTheme,
    toggleTheme,
  }
}

export default useTheme;
