export enum NovaElementsOptions {
    Primary = 'primary',
    Secondary = 'secondary',
    Default = 'default'
  }
  
export enum NovaElementsSizes {
  Big = 'big',
  Small = 'small',
  Smaller = 'smaller',
  Smallest = 'smallest',
  Medium = 'medium',
  MediumSmall = 'medium-small'
}

export enum Cases {
  Lowercase = 'lowercase',
  Uppercase = 'uppercase',
}

export enum DropdownMode {
  Scroll = 'scroll',
  Select = 'select',
}

// export enum NotificationTrigger {
//   signin = 'signin',

//   kyc_approved = 'kyc_approved',
//   kyc_rejected = 'kyc_rejected',
//   kyc_incomplete = 'kyc_incomplete',

//   order_completed = 'order_completed',
//   order_rejected = 'order_rejected',
//   order_cancelled = 'order_cancelled',

//   conversion_completed = 'conversion_completed',
//   conversion_rejected = 'conversion_rejected',

//   payment_new = 'payment_new',
//   payment_rejected = 'payment_rejected',
//   payment_completed = 'payment_completed',
// }
