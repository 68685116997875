/**
 * Translate function
 *
 * @return {String}
 */
const translate = () => {
  const replaceVariables = (str: string, variables: Object) => {
    Object.keys(variables).forEach((prop) => {
      const val = variables[prop as keyof typeof variables];
      str = str.replace(new RegExp(`{${prop}}`, 'g'), (val as any ?? ''));
    });
    return str;
  };

  return (str: string, translationKey: string, variables?: Object) => {
    let translation = (window.translations || {})[translationKey]
      ? window.translations[translationKey]
      : str;

    if (variables) {
      translation = replaceVariables(translation, variables);
    }

    return translation;
  };
};

export default translate;
