import React from 'react';

import Router from './route/Router';

import ThemeProvider from '#/styles/ThemeProvider';
import SessionProvider from './hoc/sessionProvider/SessionProvider';
import NovaWithTranslation from './hoc/nova-with-translation/NovaWithTranslation';

import { routeComponents } from '#/route/routeComponents';
import { useTheme } from './hooks';

const App: React.FC = (): JSX.Element => {
  const { theme } = useTheme();

  return (
    <NovaWithTranslation>
      <ThemeProvider theme={theme}>
        <SessionProvider>
          <Router components={{ ...routeComponents }} />
        </SessionProvider>
      </ThemeProvider>
    </NovaWithTranslation>
  );
};

export default App;
