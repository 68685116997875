export enum Languages {
  EnUs = 'en-us',
  En = 'en',
  ZhCn = 'zh-CN',
  Por = 'pt',
  Esp = 'es',
  Ger = 'de',
  Pol = 'pl',
}

export interface LangItems {
  [langName: string]: string,
}

export interface LangConfig {
  default: Languages,
  items: LangItems,
}

const langConfig: LangConfig = {
  default: Languages.En,
  items: {
    [Languages.En]: 'English',
    [Languages.Pol]: 'Polish',
    [Languages.Esp]: 'Spanish',
    [Languages.Ger]: 'German',
  },
};

export default langConfig;
