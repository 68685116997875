import { combineReducers, configureStore } from '@reduxjs/toolkit';

import configReducer from './reducers/config/config';
import sessionReducer from './reducers/session/session';
import ecommpayReducer from './reducers/ecommpay/ecommpay';

import services, { IServices } from '#/api';

export const rootReducer = combineReducers({
  session: sessionReducer,
  ecommpay: ecommpayReducer,
  config: configReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      thunk: {
        extraArgument: services,
      },
      serializableCheck: false,
    })
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export interface AsyncThunkApi {
  getState: () => RootState;
  dispatch: AppDispatch;
  extra: IServices;
}
