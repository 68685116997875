import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

import {
  SessionStatus,
  setTargetCurrency,
  setSession,
  setSessionStatus,
  setClientName,
  setCustomerName,
  setAmountRules,
  setSourceCurrency,
  setInitialAmount,
} from './session';

import { setLanguage, updateSiteTheme } from '../config/config';
import { Themes, UrlSearchParamsEnum } from '#/types';
import langConfig, { Languages } from '#/config/language/langConfig';

import { AsyncThunkApi } from '#/store/store';
import { throttle } from '#/util';

export const initSession: AsyncThunk<void, { [key in UrlSearchParamsEnum]: string }, AsyncThunkApi> = createAsyncThunk(
  'session/init',
  throttle(
    async (props: { [key in UrlSearchParamsEnum]: string }, { dispatch, extra }: AsyncThunkApi) => {
      const id = props[UrlSearchParamsEnum.SessionId];

      if (!id) {
        dispatch(setSessionStatus(SessionStatus.Invalid));

        return;
      }
      const currentTheme = props[UrlSearchParamsEnum.Theme] || Themes.Light;
      const currentLanguage = props[UrlSearchParamsEnum.Language] || langConfig.default;
      const targetCurrency = props[UrlSearchParamsEnum.SelectedCurrency];

      const { epay } = extra;
      epay.currentCurrency = targetCurrency;
      epay.session = id;

      dispatch(updateSiteTheme(currentTheme as Themes));
      dispatch(setLanguage(currentLanguage as Languages));
      dispatch(setSession(id));
      dispatch(setTargetCurrency(targetCurrency));
      dispatch(setClientName(props[UrlSearchParamsEnum.ClientName]));
      dispatch(setCustomerName({ firstName: props[UrlSearchParamsEnum.CustomerFirstName], lastName: props[UrlSearchParamsEnum.CustomerLastName] }));
      dispatch(setAmountRules({
        min: Number(props[UrlSearchParamsEnum.MinAmount]),
        max: Number(props[UrlSearchParamsEnum.MaxAmount]),
        availableAmount: props[UrlSearchParamsEnum.AvailableAmount] ? Number(props[UrlSearchParamsEnum.AvailableAmount]) : undefined,
      }));
      dispatch(setSourceCurrency(props[UrlSearchParamsEnum.SourceCurrency]));
      dispatch(setInitialAmount(props[UrlSearchParamsEnum.InitialAmount]));
    }, 500)
);
