import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import config, { Config } from '#/config/config';
import { Themes } from '#/types/enums';
import { Languages } from '#/config/language/langConfig';
import { RootState } from '#/store/store';

const initialState: Config = config;

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateSiteTheme(state: Config, action: PayloadAction<Themes>) {
      state.theme = action.payload;
      localStorage.setItem(Themes.LocalStorageThemeItem, action.payload);
    },
    setIsMobile(state: Config) {
      state.isMobile = window.innerWidth <= 768;
    },
    setLanguage(state: Config, action: PayloadAction<Languages>) {
      state.currentLanguage = action.payload;
    },
  },
});

export const { setIsMobile, setLanguage, updateSiteTheme } = configSlice.actions;
export default configSlice.reducer;

export const selectConfig = (state: RootState): Config => state.config;
export const selectCurrentLanguage = (state: RootState): Languages => selectConfig(state).currentLanguage;
export const selectIsMobile = (state: RootState): boolean => selectConfig(state).isMobile;
// export const selectSiteName = (state: RootState): string => selectConfig(state).configEnv.siteName;
export const selectSiteTheme = (state: RootState): Themes => selectConfig(state).theme;
export const selectRoutesConfig = (state: RootState) => selectConfig(state).routes;
// export const selectConfigRedirectAfterLogin = (state: RootState): string => selectConfig(state).redirectAfterLogin;
