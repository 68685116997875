import altLoader from '../../../images/loaders/alt-loader.webp';

import './loader.scss';

interface Props {
  active?: boolean,
  isStatic?: boolean,
  isAltLoader?: boolean,
}

const Loader = (props: Props) => {
  const { active = true, isStatic = false, isAltLoader = false } = props;

  if (!active) {
    return null;
  }

  const renderContent = () => isAltLoader ? <img src={altLoader} alt="loader" /> : (
    <>
      <i className="loader-layer" />
      <i className="loader-layer" />
      <i className="loader-layer" />
    </>
  );

  return (
    <div role="loader" className={`nova-loader-container ${isStatic ? 'static-loader' : ''} ${altLoader ? 'alt-loader' : ''}`}>
      {renderContent()}
    </div>
  );
}

export default Loader;
