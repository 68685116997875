import { apiURL } from '../config/config-env';
import EpayService from './epay';

export interface IServices {
  epay: EpayService;
}

const services: IServices = {
  epay: new EpayService(apiURL),
};

export default services;
