import { ComponentType, lazy } from 'react';

export const lazyMinLoadTime = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  minLoadTimeMs = 800
) =>
  lazy(() =>
    Promise.all([factory(), new Promise(resolve => setTimeout(resolve, minLoadTimeMs))]).then(
      ([moduleExports]) => moduleExports
    )
  );
