import langConfig, { LangConfig, Languages } from '#/config/language/langConfig';
import { Themes } from '#/types/enums';
import { routes } from '#/route/routes';
import poeditorConfig, { PoeditorConfig } from '#/config/poeditor/poeditorConfig';
import getCurrentLang from '#/util/translation/getCurrentLang';
import { IRoutes } from '#/route/types';

export interface Config {
  langConfig: LangConfig;
  currentLanguage: Languages;
  poeditorConfig: PoeditorConfig;
  isMobile: boolean;
  dateFormat: string;
  routes: IRoutes[];
  // timeFormat: string,
  theme: Themes;
}

const config: Config = {
  langConfig,
  currentLanguage: getCurrentLang(),
  poeditorConfig,
  routes,
  isMobile: window.innerWidth <= 768,
  dateFormat: 'MMMM dd, yyyy', // e.g. 'MM', 'DD', 'YY', 'YYYY', SEE moment.js FOR MORE OPTIONS
  // timeFormat: '12hr', // '24hr' or '12hr'
  theme: (localStorage.getItem(Themes.LocalStorageThemeItem) as Themes) || 'light',
};

export default config;
